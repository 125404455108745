$dark-color: #333333;
$light-color: #f4f4f4;
$primary-color: #ffbf00;

$project-tag-color: #feffe0;

$container-width: 900px;

$font-stack: Arial, Helvetica, sans-serif;

$break-mobile: 900px;

$mq-mobile: 'only screen and (max-width: 900px)';