section#projects {
    #projects-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        margin: 1.4rem 0rem;

        @media #{$mq-mobile} {
            grid-template-columns: 1fr;
        }

        .project {
            background: $light-color;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
    
            img {
                width: 100%;;
            }
    
            .project-content, .project-actions {
                padding: 1rem;
                text-align: center;
            }
    
            .project-content {
                flex: 1
            }
    
            .project-actions .btn {
                padding: 0.3rem 1.1rem;
            }

            .tags { 
                padding: 1rem 0.5rem;
                
                span {
                    display: inline-block;
                    background: $project-tag-color;
                    padding: 0.4rem;
                    border: 1px solid darken($project-tag-color, 50);
                    margin: 0.25rem;
                }
            }
        }
    }
}