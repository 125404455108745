@import '_variables.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: $font-stack;
    scroll-behavior: smooth;

    @media (prefers-reduced-motion: reduce) {
        scroll-behavior: auto;
    }
}

body {
    line-height: 1.6rem;
}

h1, h2, h3, h4, h5, h6 {
    padding-bottom: 1rem;
}

h1 {
    font-size: 3rem;

    @media #{$mq-mobile} {
        font-size: 2.5rem;
    }
}

h2 {
    font-size: 2.3rem;
}

p {
    font-size: 1.2rem;
    padding: 1.3rem 0;
    margin: 0;

    @media #{$mq-mobile} {
        padding: 0.75rem 0;
    }
}

a {
    text-decoration: none;
    color: $primary-color;
    font-weight: bold;
}

ul {
    list-style: none;
}

section {
    padding: 2rem;
}