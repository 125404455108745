@import '../_variables.scss';

.webp header {
        background: $light-color url(../img/showcase.webp) center center/cover;
}

header {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: $light-color url(../img/showcase.jpg) center center/cover;

    #header-content {
        z-index: 2;
        margin-top: 7.5rem;
        align-self: center;
        text-align: center;

        @media #{$mq-mobile} {
            margin-top: 4rem;
        }

        p {
            line-height: 2.5rem;

            @media #{$mq-mobile} {
                padding: 1rem 2rem;
            }
        }

        a .icon {
            vertical-align: middle;
            font-size: 22px;
        }
    }
}

/* Overlay for the showcase */
header:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
}