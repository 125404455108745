@import '../_variables.scss';

footer {
    padding: 1.5rem;
    background: $dark-color;
    color: #ffffff;

    a {
        color: #ffffff;
    }

    #social ul {
        display: flex;
        flex-direction: row;
        justify-content: center;

        li {
            margin-left: 1.75rem;
        }

        @media #{$mq-mobile} {
            justify-content: space-around;
        }
    }

    @media #{$mq-mobile} {
        padding: 0.5rem;
    }
}