@import '../_variables.scss';

section#contact {
    background: $light-color;
    padding-bottom: 3rem;

    .form-wrapper {
        max-width: 80%;
        margin: auto;

        form {
            display: flex;
            flex-direction: column;
            align-items: stretch;

            .form-group {
                margin-top:1.7rem;

                label {
                    display: block;
                    font-size: 1.1rem;
                    color: $dark-color;
                    margin-bottom: 0.3rem;
                }

                input:not([type='submit']), textarea {
                    width: 100%;
                    padding: 0.5rem;
                    font-size: 1.1rem;
                    border: #ddd 1px solid;
                    border-radius: 0.3rem;;
                }
            }

            input[type='submit'] {
                margin-top: 1.5rem;
                align-self: center;
            }
        }

        @media #{$mq-mobile} {
            max-width: 100%;
        }
    }
}